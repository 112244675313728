import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "./Loader";
import { useForm, Controller } from "react-hook-form";
import MUISelect from "./MUISelect";
import FormControl from "@mui/material/FormControl";
import info from "../assets/img/info.svg";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getnetworks } from "../store/slices/networks";
import { getLabels, getUserAccounts } from "../store/slices/general";
import { setBalance, setCurrency } from "../store/slices/general";
import { jwtDecode } from "jwt-decode";
import { widthDraw } from "../store/slices/deposit";
import { renderMoney } from "../utils/helpers";
import { Tooltip as ReactTooltip } from "react-tooltip";

const WithdrawCard = () => {
  const dispatch = useDispatch<any>();

  const {
    networks: networkData,
    global,
    deposit,
  } = useSelector((state: any) => state);
  const { networks } = networkData;
  const { accounts, currency, labels } = global;

  const { loading } = deposit;
  const { control, handleSubmit, watch, setValue } = useForm();
  const [options, setOptions] = useState([""]);
  console.log("options: ", options);
  const [netIban, setShowNewIban] = useState(labels?.length === 0);
  const [newAddress, setNewAddress] = useState(false);

  useEffect(() => {
    setShowNewIban(labels?.length === 0);
  }, [labels]);

  useEffect(() => {
    dispatch(getnetworks());
    dispatch(getUserAccounts(true));
  }, [dispatch]);

  const globals = watch("globals");
  const amount = watch("amount");
  const address = watch("address");
  const bankSelected = watch("bank");

  useEffect(() => {
    let labelsArray = [...(labels || [])];

    const selectedBank = labelsArray?.filter(
      (item) => item.address === bankSelected
    )[0];
    if (bankSelected === "Enter New Address") {
      setShowNewIban(true);
      setNewAddress(true);
    }
    setValue("address", selectedBank ? selectedBank?.label : "");
  }, [bankSelected, labels, setValue]);

  const onSubmit = (data: any) => {
    let payload = {
      network: data?.netWorkValue,
      amount: data?.amount || "",
      symbol: currency,
      address: data?.bank,
      label: data?.address,
    };

    console.log("payload: ", payload);
    dispatch(widthDraw(payload));
  };

  const renderNetworks = useCallback(() => {
    return networks
      ?.filter((item: any) =>
        item?.symbols.some((symbol: any) => symbol.id === currency)
      )
      .map((item: any) => {
        return (
          <MenuItem
            key={item["_id"]}
            className="border-b-darkBlueGray  text-lightGray2 h-[56px]"
            value={item["_id"]}
            sx={{
              fontSize: "16px",
              paddingX: "0px",
              borderColor: "#444869",
            }}
          >
            <div className="px-2">{item["_id"]}</div>
          </MenuItem>
        );
      });
  }, [networks, currency]);

  const renderCoins = useCallback(() => {
    return accounts?.map((item: any, index: number) => {
      return (
        <MenuItem
          className=" border-b-darkBlueGray  text-lightGray2 h-[56px]"
          value={item?.symbol}
          sx={{
            fontSize: "16px",
            paddingX: "0px",
            borderBottom: accounts.length - 1 === index ? "" : "1px solid",
            borderColor: "#444869",
          }}
        >
          <div className="px-2">{item?.symbol}</div>
        </MenuItem>
      );
    });
  }, [accounts]);

  const renderLabels = useCallback(() => {
    let newObject = [
      ...options,
      {
        address: "Enter New Address",
        label: "+",
      },
    ];
    return newObject?.map((item: any, index: number) => {
      return (
        <MenuItem
          className=" border-b-darkBlueGray  text-lightGray2 h-[56px]"
          value={item?.address}
          sx={{
            fontSize: "16px",
            paddingX: "0px",
            borderBottom: newObject.length - 1 === index ? "" : "1px solid",
            borderColor: "#444869",
          }}
        >
          <div className="px-2">
            {newObject.length - 1 === index
              ? item?.address
              : `${item?.label} - ${item?.address}`}
          </div>
        </MenuItem>
      );
    });
  }, [options]);

  useEffect(() => {
    dispatch(getLabels(currency));

    const selectedNetwork = networks?.find((item: any) =>
      item?.symbols.some((symbol: any) => symbol.id === currency)
    );
    const findBalance = accounts?.find(
      (item: any) => item?.symbol === currency
    );
    const symbolObj = selectedNetwork?.symbols.find(
      (symbol: any) => symbol.id === currency
    );
    if (selectedNetwork || globals) {
      setValue("netWorkValue", selectedNetwork["_id"]);
      setValue("globals", {
        balance: renderMoney(findBalance?.balance, findBalance?.symbol),
        commission: "0",
        pnl: findBalance?.pnl,
        symbol: findBalance?.symbol,
        unsettled_balance: findBalance?.unsettled_balance,
        fees: symbolObj.fee,
      });
    }
  }, [networks, currency, accounts, dispatch, setValue]);

  useEffect(() => {
    const array = [...(labels || [])];
    setOptions(array);
  }, [labels]);

  useEffect(() => {
    const token = window.localStorage.getItem("token") || "{}";
    const decoded = jwtDecode(token) as any;
    if (currency === "EUR") setValue("transferEmail", decoded["email"]);
  }, [accounts, currency, setValue]);

  const balanceMinusFee: any = (
    balance: string,
    currency: string,
    fee: string
  ) => {
    const money = Math.max(Number(balance) - Number(fee), 0);
    return renderMoney(money, currency);
  };

  return (
    <div>
      {loading && <Loader />}
      <ToastContainer hideProgressBar={true} />
      <div className="md:w-[90%] mx-auto  w-[534px] p-[30px] bg-darkBlue rounded-[24px] border-[1px] border-darkBlueGray sm:p-[16px] mb-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <label
              htmlFor="currency"
              className="text-white text-[17px] mb-2 block"
            >
              Select Coin
            </label>
            <FormControl fullWidth>
              <Controller
                name="currency"
                control={control}
                render={() => (
                  <MUISelect
                    value={currency}
                    onChange={(e: any) => {
                      dispatch(setCurrency(e.target.value));
                      dispatch(setBalance(e.target.value));
                    }}
                  >
                    {renderCoins()}
                  </MUISelect>
                )}
              />
            </FormControl>
          </div>

          <div className="mt-6">
            <label
              htmlFor="netWorkValue"
              className="text-white text-[17px] mb-2 block"
            >
              Select network
            </label>
            <FormControl fullWidth>
              <Controller
                name="netWorkValue"
                control={control}
                render={({ field }) => (
                  <MUISelect {...field}>{renderNetworks()}</MUISelect>
                )}
              />
            </FormControl>
          </div>

          <div className="mt-6 relative">
            <label
              htmlFor="#"
              className="text-white text-[17px] flex mb-2 flex-1 justify-between"
            >
              {currency !== "EUR" ? "Address " : "IBAN "}
              {labels?.length > 0 && (
                <p
                  className="text-xs py-1 text-white cursor-pointer"
                  onClick={() => {
                    setShowNewIban((prev) => !prev);
                    netIban && setNewAddress(false);
                  }}
                >
                  {netIban ? "Use Saved " : "Enter New "}
                  {currency !== "EUR" ? "Address " : "IBAN "}
                </p>
              )}
            </label>

            <FormControl fullWidth>
              {bankSelected && newAddress ? (
                <div
                  className={`flex bg-darkBluish w-full rounded-[12px] border-[1px] border-darkBlueGray1 h-[50px] items-center px-3 cursor-pointer ${
                    address &&
                    address?.length > 1000 &&
                    "rounded-bl-none rounded-br-none"
                  }`}
                >
                  <input
                    type="text"
                    className="bg-[transparent] sm:text-[12px] text-lightGray text-[16px] flex-1 w-full outline-none border-0"
                    onChange={(e) => {
                      setValue("bank", e.target.value);
                    }}
                  />
                </div>
              ) : (
                <Controller
                  name="bank"
                  control={control}
                  render={({ field }) => (
                    <MUISelect {...field}>{renderLabels()}</MUISelect>
                  )}
                />
              )}
            </FormControl>
          </div>

          {netIban && (
            <div className="mt-6 relative">
              <label htmlFor="#" className="text-white text-[17px] mb-2 block">
                {currency !== "EUR" ? "Address " : `IBAN `} Name
              </label>

              <div
                className={`flex bg-darkBluish w-full rounded-[12px] border-[1px] border-darkBlueGray1 h-[50px] items-center px-3 cursor-pointer ${
                  address &&
                  address?.length > 1000 &&
                  "rounded-bl-none rounded-br-none"
                }`}
              >
                <input
                  type="text"
                  className="bg-[transparent] sm:text-[12px] text-lightGray text-[16px] flex-1 w-full outline-none border-0"
                  // placeholder={`Enter New ${
                  //   currency !== "EUR" ? "Address " : "IBAN "
                  // }`}
                  disabled={
                    bankSelected ===
                    labels?.filter(
                      (item: any) => item.address === bankSelected
                    )[0]?.address
                  }
                  value={address}
                  onChange={(e) => {
                    setValue("address", e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className="mt-6">
            <label htmlFor="#" className="text-white text-[17px] mb-2 block">
              Amount
            </label>
            <div className=" bg-darkBluish w-full rounded-[12px] border-[1px] border-darkBlueGray1 h-[50px]  px-3 cursor-pointer flex flex-col justify-center">
              <div className="flex items-center">
                <input
                  type="text"
                  className="bg-[transparent] sm:text-[12px] text-lightGray text-[16px] flex-1 w-full outline-none border-0 placeholder:text-lightGray2"
                  placeholder={`${
                    globals &&
                    balanceMinusFee(
                      globals["balance"],
                      globals?.symbol,
                      globals?.fees
                    )
                  }`}
                  value={amount}
                  onChange={(e) => {
                    const rawValue = e.target.value;
                    const formattedValue = rawValue.replace(/,/g, ".");
                    setValue("amount", formattedValue);
                  }}
                />
                <button
                  type="button"
                  className="text-lightGray2 text-[15px] border-[1px] border-blue  h-[31px] w-[60px] rounded m-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(
                      "amount",
                      balanceMinusFee(
                        globals["balance"],
                        globals?.symbol,
                        globals?.fees
                      )
                    );
                  }}
                >
                  Max
                </button>

                <img
                  src={info}
                  alt=""
                  data-tooltip-id={"maximum"}
                  className="info-area cursor-pointer"
                />
                <ReactTooltip
                  className="break-words  max-w-52 z-30 text-sm font-normal !bg-[#3958FF]"
                  id={"maximum"}
                  place="bottom"
                  content={"Maximum available balance minus the fee"}
                />
              </div>
            </div>
            <p className="text-lightGray2 text-[12px] mt-2">
              Available{" "}
              {globals && renderMoney(globals["balance"], globals?.symbol)}{" "}
              {globals?.symbol}
            </p>
          </div>

          <div className="mt-4 mb-6">
            <div className="flex w-full    text-[17px]  h-[50px] items-center  cursor-pointer">
              <input
                type="password"
                className="bg-[transparent]  text-lightGray placeholder:text-white text-[16px] flex-1 w-full outline-none border-0"
                readOnly
                placeholder="Fee"
              />
              <p className="text-lightGray2 text-[18px] font-semibold">
                {globals?.fees} {globals?.symbol}
              </p>
            </div>
          </div>

          <button
            className="text-white tect-[16px] w-full h-[49px] bg-blue  rounded-[12px]"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default WithdrawCard;
