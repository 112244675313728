import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Header } from "./Header";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserAccounts, setCurrency } from "../store/slices/general";
import { useDispatch } from "react-redux";

interface LayoutIProps {
  children: React.ReactNode;
  active?: number;
  page?: string;
  loading?: boolean;
}

const Layout = ({ children, loading, active, page }: LayoutIProps) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const location = useLocation();

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    const isFirstVisit = !localStorage.getItem("visited");

    if (isFirstVisit) {
      clearLocalStorage();
      localStorage.setItem("visited", "true");
    }
  }, [location.pathname]);

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const currency = window.localStorage.getItem("currency");
    if (!currency) {
      dispatch(setCurrency<any>("BTC"));
      dispatch(getUserAccounts(true));
    }
  }, [dispatch]);

  if (!isAuthenticated) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {loading && <Loader />}

      {page && active && <Header active={active} page={page} />}
      <div className="flex-1">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
