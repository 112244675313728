import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../utils/axios-utils";

const initialState = {
  accounts: [],
  labels: [],
  loading: false,
  currency: "EUR",
  balance: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setCurrency: (state: any, action: any) => {
      state.currency = action.payload;
      window.localStorage.setItem("currency", JSON.stringify(action.payload));
    },
    setBalance: (state: any, action: any) => {
      const currencyAccount = state.accounts.find(
        (item: any) => item.symbol === action.payload
      );

      if (currencyAccount) {
        state.balance = currencyAccount;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getLabels.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLabels.fulfilled, (state, action) => {
      state.labels = action.payload.labels;
    });
    builder.addCase(getLabels.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUserAccounts.pending, (state, action) => {
      if (action.meta.arg === true)
      {
        state.loading = true
      }
    });
    builder.addCase(getUserAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;

      const currencyAccount = action.payload.find(
        (item: any) => item.symbol === state.currency
      );

      if (currencyAccount) {
        state.balance = currencyAccount;
      }
      state.loading = false;

      window.localStorage.setItem("currency", JSON.stringify(state.currency));
    });
    builder.addCase(getUserAccounts.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const getUserAccounts = createAsyncThunk(
  "general/getUserAccounts",
  async (showLoader: boolean = true) => {
    const { data } = await request({
      url: `/accounts`,
    });

    return data.accounts;
  }
);

export const getLabels = createAsyncThunk(
  "withdrawals/labels",
  async (payload: any) => {
    console.log("payload: ", payload);
    const data = await request({
      url: `/withdrawals/labels?symbol=${payload}`,
      method: "get",
    });

    return data.data;
  }
);

export const { setCurrency, setBalance } = generalSlice.actions;

export default generalSlice.reducer;
