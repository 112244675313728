const Topics = [
  {
    heading: "Essentials",
    subpara:
      "Explore key ideas that will empower you to navigate the world of betting with confidence and clarity.",
    list: [
      {
        heading: "Definitions",
        para: [
          `Bookie: a sports betting company that provides markets for betting.`,
          `Financial product: instruments that enable individuals and institutions to manage and invest their money.`,
          `Stake: the amount committed (position size) in a financial product (bet, in this context).`,
          `Leverage: the ability to invest a larger amount than what is available; amplifies potential gains, but also potential losses. For example, 2x (or 2:1) leverage means having a position double the size of the collateral for a position.`,
          `Odds: the price offered by the bookie for a certain outcome in an event. We focus on decimal odds, which represent the return for the better in case of a win. If the odd of an event is 1.5, it means the user gets the stake times 1.5 in case of a win, resulting in a 50% profit. The potential profit is the odd minus one, while the odd itself represents the factor by which the balance moves in case of a won bet. For a $100 bet, the profit in case of a win is $50, and the final balance is $100 x 1.5 = $150.`,
          `Bot: a software application designed to perform automated tasks; short for "robot". Methodical and not prone to human error.`,
          `Law of Large Numbers: it is a fundamental concept in probability theory and statistics. It says that with a sufficiently large sample size, the observed outcomes will more closely resemble the expected outcomes predicted.`,
          `Profit and Loss (P&L): represents the amount gained or lost.`,
        ],
      },
    ],
  },
  {
    heading: "Key Insights",
    subpara:
      "Let us understand why we are confident in our ability to make money over time. This journey will not only shed light on our strategies but also give you a solid grasp of important financial concepts.",
    list: [
      {
        heading: "Sports betting is considered an inefficient financial product!",
        para: [
          `While many view sports betting as entertainment, we recognize it as a financial endeavor. Mismanagement of finances, often due to psychological factors, can lead to addiction and other issues.`,
          `In an efficient market, prices reflect all available information. Conversely, an inefficient market fails to incorporate all information, presenting opportunities for exploitation.`,
          `Bookies prioritize offering diverse markets over perfect pricing, especially in less popular markets where less money is at stake. This creates opportunities for profit, such as in smaller football markets or niche sports like cycling and Formula 1.`,
          `Bookies determine odds based on the probability of an outcome. For example, in a fair coin toss, the odds of heads or tails would be 2. The Law of Large Numbers suggests that with a large enough sample size (many bets), observed outcomes approach expected probabilities, reducing the impact of randomness.`,
          `While luck may yield short-term wins in gambling, over time, probabilities dictate that the house (casino) holds the advantage, leading to net losses for players.`,
          `Let us consider a simple example using roulette, where we choose between red or black. The chance of winning each time is slightly below 50%, say 48%, due to a neutral number favoring the casino. If we play roulette 10 times, the expected wins are 4.8, but actual outcomes can vary significantly. However, if we play 100,000 times, we will likely win around 48,000 times, with rare occurrences of winning over 50,000 times. The casino profits over time as long as people keep playing. Similarly, in stock picking, consistently choosing undervalued stocks can lead to long-term profits despite occasional losses.`,
          `When individuals outsmart the system, such as card counting in Blackjack, the odds tilt in their favor, increasing their likelihood of winning with more play, despite inevitable losses along the way.`,
          `Importantly, even a slight advantage over the system can result in significant profits, as seen in the casino example where a 52% win probability yields substantial gains with enough volume. Patience is key to capitalizing on these opportunities.`,
          `Here, we are fortunate to sometimes have a 10% advantage over the probabilities the bookies believe to be correct for an event. This gives us a significant edge and reduces the chance of long losing streaks. It is essential to approach losses calmly and objectively, particularly when the overall statistics are in our favor. We adhere to our strategy without allowing emotions to influence our decisions, while consistently assessing the accuracy of our probability estimates, whether in winning or losing situations.`,
          `The fair coin toss example illustrates this concept clearly. If we toss it infinitely, we'd expect our desired outcome half the time, resulting in no overall gain or loss. An efficient market offers odds that accurately reflect event probabilities. Bookies, like casinos, add a margin ensuring long-term profits. However, if we believe the actual probability differs from their estimation, say 60% instead of 50%, we can still profit.
          Our advantage lies in betting in smaller, less efficiently priced markets where discrepancies are more pronounced. We focus on in-play betting during matches, as this is where significant inefficiencies often occur. We place our bets when conditions favor our edge and patiently wait when opportunities are scarce.`,
        ],
      },
      {
        heading: "Our purpose",
        para: [
          `Our platform aims to share our experience of successfully making profits against bookmakers for over 16 years. While it might seem logical to keep this knowledge to ourselves and continue quietly maximizing profits, we believe in creating value for others.`,
          `Democratizing this additional source of income is our goal. Having personally achieved financial success, our priority now is to create a win-win situation for everyone involved.`,
          `We aim to challenge the status quo of sports betting companies that often exploit individuals.`,
          `In addition to providing a passive income opportunity, we want to educate our users about essential money management concepts like Risk Management. Emotional control plays a significant role, and understanding the psychology of money is crucial.`,
        ],
      },
      {
        heading: "Freedom X's unique attributes",
        para: [
          `Unlike existing platforms that thrive on promoting gambling addiction without concern for sustaining gains, we prioritize long-term profitability. Many of these platforms even have partnerships with bookmakers, indicating their focus on profiting from users' losses rather than helping them win. They do not charge users because bookmakers pay them for directing traffic to their sites.`,
          `Our approach to sports betting sets us apart. We apply advanced trading concepts and meticulously measure our results. Unlike other platforms that boast about wins while concealing losses, we provide transparent data. Our track record in the Analytics tab shows both profits and losses for each bet, giving users an honest picture of our performance.`,
          `We believe that any entity presenting results as transparently, as we do, deserves respect. We welcome more platforms to adopt such an analytical approach, but unfortunately, many rely solely on marketing tactics to appear knowledgeable without substantial evidence.`,
          `It is challenging to showcase results objectively when emotions run high in betting. However, we remain committed to providing transparent and objective information to educate users about the normal fluctuations in betting outcomes.`,
        ],
      },
      {
        heading: "Business model",
        para: [
          `While our aim is to make gains accessible to everyone, it is essential to sustain the effort with charges. As users recognize our advantage over the market, the potential impact on their lives becomes evident. Our Analytics page, constantly updated, serves as community proof.`,
          `For transparency, the displayed odds include a 0.5% cut compared to our original bet placement with bookies. This ensures we profit only when users do, receiving that 0.5% difference.`,
          `Our intention is to redirect most of the commissions to support impactful projects, driving positive change globally. This model not only offers financial freedom but also contributes to meaningful causes. Additionally, the considerable time and energy invested in this endeavor deserve acknowledgment.`,
        ],
      },
      {
        heading: "Creating a sports betting bot",
        para: [
          `In financial markets, bots can execute trades based on coded rules, including risk management and stake sizing, identifying opportunities and operating autonomously.`,
          `In this context, while most of the process is automated, some bets are chosen manually to capture advantageous odds, often by observing events in real-time and reacting swiftly. However, the actual bet placement and determining the optimal stake size are entirely automated.`,
        ],
      },
      {
        heading: "Using the Kelly Criterion for take allocation",
        para: [
          `As discussed earlier, accurately assessing probabilities is crucial for our approach. Equally important is how we use these probabilities to decide when and how much to bet.`,
          `Odds represent probabilities, and implied probability is the likelihood associated with the odds offered by the bookie. We target markets where we believe the actual probability exceeds the implied probability.`,
          `The Kelly Criterion, originally devised for sports betting and later adapted to financial markets, aims to maximize capital growth over time. It calculates the percentage of our total capital to stake based on our estimated win probability and the disparity between our estimate and the bookie's implied probability. The criterion advises higher stakes for higher win probabilities, especially when the difference between our estimate and the implied probability is significant.`,
          `Our bot follows this approach: it compares our win probability estimate with the implied probability, automatically determines the stake percentage, and places the bet accordingly.`,
          `However, the Kelly Criterion has limitations. One of the key principles of investing is to avoid losses, as famously emphasized by Warren Buffet. Therefore, it is crucial to consider downside risk. The criterion's stakes can be overly aggressive, contradicting risk management principles. To address this, we use a modified version, multiplying its suggested stakes by a conservative constant to ensure exposure remains below 5% for each bet.`,
          `Despite this cautious approach, our track record demonstrates remarkable returns with minimal risk, thanks to the significant disparities we sometimes identify between our estimated and implied probabilities. We patiently wait for these opportunities and capitalize on them.`,
        ],
      },
    ],
  },
  {
    heading: "Frequently Asked Questions",
    subpara:
      "Get quick answers to common questions and gain insights into Freedom X.",
    list: [
      {
        heading: "What knowledge do I need before using the product?",
        para: [
          `To get started, simply create an account and deposit funds, and we will handle the rest, making money for you.`,
          `Moreover, it is recommended to familiarize yourself with the financial concepts we discuss here to understand our commitment to making profits and differentiate us from typical gambling ventures.`,
        ],
      },
      {
        heading: "How can I be sure that following this process will result in long-term profits?",
        para: [
          `Some bets offered by bookmakers are inefficient, meaning the probabilities associated with the odds they provide are often incorrect. This presents an opportunity for profit. Bookmakers prioritize offering numerous markets over ensuring the accuracy of their odds, as they profit regardless due to the lack of mathematical knowledge among many users. Especially in smaller sports or less common markets of major sports, we have identified and explored numerous inefficiencies over the years.`,
          `Consistently betting on inefficient markets leads to long-term profits for a simple reason: in a casino, prolonged betting results in losses due to the Law of Large Numbers.`,
        ],
      },
      {
        heading: "Why do you set fixed stakes instead of allowing users to choose?",
        para: [
          `Setting fixed stakes serves to mitigate two potential issues. Firstly, it helps prevent significant drawdowns that could lead to the account being wiped out, as the probability of drawdowns larger than 20% is extremely low. Secondly, it addresses concerns related to money psychology. Allowing users to adjust stakes may lead to fluctuations in their net worth, potentially triggering risky behaviors. We prioritize user safety and aim to prevent individuals from exposing themselves to such risks.`,
          `While some users may be capable of managing stakes responsibly, our primary concern is ensuring the safety of all users. Since risk management is vital in portfolio management, offering fixed stakes ensures a responsible approach. Despite the fixed stakes, the annualized returns remain impressive, with a low probability of encountering a negative month, as indicated on the Analytics page.`,
        ],
      },
      {
        heading: "Why is there a fee for withdrawals?",
        para: [
          `There are two main reasons for this. Firstly, crypto withdrawals involve network fees, which incur costs on our end. To ensure consistency, we have set a fixed fee that covers these expenses. Secondly, withdrawals are currently processed manually, requiring human work to verify ownership and process the transaction. As such, the fee helps compensate for this manual processing.`,
        ],
      },
      {
        heading: "How long will my withdrawal take to be processed?",
        para: [
          `Due to the fact that this is a manual process that requires review, and mainly because we need to wait for the withdrawal to be processed on the bookie's side (which often takes the longest, as they do not really love people withdrawing, so they usually make sure it takes some time), it can take up to 10 business days for the amount to be in your account.`,
        ],
      },
      {
        heading: "Why can my P&L be negative?",
        para: [
          `This is called drawdown. Over time, though, the Law of Large Numbers will balance things out and bring the P&L to where it should be!`,
        ],
      },
      {
        heading: `Why is the P&L percentage in Overall Performance different from my account values? `,
        para: [
          `Deposits or withdrawals change your available balance, affecting the P&L calculation. The P&L shown assumes the balance stayed the same during the desired period. If your balance changed, the P&L needs to be adjusted accordingly. Overall Performance provides a clearer view of P&L because it assumes a consistent balance for all bets.`,
        ],
      },
      {
        heading: "Are my funds safe?",
        para: [
          `We are ensuring 100% account safety through manual verification for each withdrawal request.`,
        ],
      },
      {
        heading: "Why are the stakes per bet so low, when we are clearly in advantage over the bookies?",
        para: [
          `Besides the arguments above concerning risk management, we have been forced to reduce them beyond what is optimal because the amount we are managing has grown too quickly, and we are currently working on finding solutions to be able to bet the desired sizes again. In the meantime, we are not allowing further deposits, as this would hurt the performance for those who have deposited in the past.`,
        ],
      },
    ],
  },
];

export { Topics };
